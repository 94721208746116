<template>
  <div>
    <core-page-title />

    <v-container>
      <v-row>
        <v-col>
          <btn-back to="/reports" />
        </v-col>
      </v-row>

      <reports-fields
        v-model="report"
        :loading-save="loading"
        :form-validation="formValidation"
        @save="save"
      />
    </v-container>
  </div>
</template>

<script>
  import reportsApi from '@/api/reports'

  export default {

    components: {
      ReportsFields: () => import('./ReportsFields'),
    },

    data () {
      return {
        report: {
          observations: null
        },
        loading: false,
        formValidation: {},
      }
    },

    methods: {
      async save () {
        this.loading = true
        this.formValidation = {}

        try {
          await reportsApi.insert(this.report)
          this.$router.push(
            '/reports',
            () => this.$snackbar.show({ color: 'success', message: this.$messages._('new_success') }),
          )
        } catch (e) {
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)

            e.response.data.fields.forEach(field => {
              if (field.param.includes('workShifts')) {
                this.formValidation.workShifts = [field.msg]
              }
            })

            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
